import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Showdown from 'showdown'

class listCompanies extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      divisions: this.props.data.sort((a, b) => a.title.toUpperCase() == b.title.toUpperCase() ? 0 : a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1 ),
      companies: [],
      btnActive: this.props.btnId,
    }
  }

  componentDidMount() {
    this.setState({
      companies: this.state.divisions,
      btnActive: this.props.btnId,
    })
    this.handleClick(this.props.btnId)
  }

  handleClick(value) {
    let companies = []
    this.setState({ btnActive: value })

    if (value === 'all') {
      companies = this.state.divisions
    } else {
      companies = this.state.divisions.filter((divisions) =>
        JSON.stringify(divisions.placement).match(value)
      )
    }

    companies = companies.sort((a, b) =>
      a.title.toUpperCase() == b.title.toUpperCase() ? 0 : a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1
    )
    this.setState({ companies: companies })
  }

  render() {
    const converter = new Showdown.Converter()  
    return (
      <>
        <MDBRow className="pb-5">
          <MDBCol className="pb-5">
          <MDBBtn 
              className={ this.state.btnActive === 'all' ? 'btn-mdb-select btn-sm active' : 'btn-mdb-select btn-sm' }
              value="all"
              onClick={() => this.handleClick('all')}
            >
              All
            </MDBBtn>

            <MDBBtn 
              className={ this.state.btnActive === 'Distributors - US' ? 'btn-mdb-select btn-sm active' : 'btn-mdb-select btn-sm' }
              value="Distributors - US"
              onClick={() => this.handleClick('Distributors - US')}
            >
              Distributors - US
            </MDBBtn>

            <MDBBtn
              className={ this.state.btnActive === 'Distributors - Canada' ? 'btn-mdb-select btn-sm active' : 'btn-mdb-select btn-sm' }
              value="Distributors - Canada"
              onClick={() => this.handleClick('Distributors - Canada')}
            >
              Distributors - Canada
            </MDBBtn>            

            <MDBBtn
              className={ this.state.btnActive === 'Resellers - National' ? 'btn-mdb-select btn-sm active' : 'btn-mdb-select btn-sm' }
              value="Resellers - National"
              onClick={() => this.handleClick('Resellers - National')}
            >
              Resellers - National
            </MDBBtn>

            <MDBBtn
              className={ this.state.btnActive === 'Resellers - Canada' ? 'btn-mdb-select btn-sm active' : 'btn-mdb-select btn-sm' }
              value="Resellers - Canada"
              onClick={() => this.handleClick('Resellers - Canada')}
            >
              Resellers - Canada
            </MDBBtn>
          </MDBCol>
        </MDBRow>

          {this.state.companies.map((company, index) => (
            <MDBRow className="about-item" key={index}>
              <MDBCol lg="3">
                <GatsbyImage image={company.image.childImageSharp.gatsbyImageData} alt="company logo" className="mb-3 mb-lg-0" />
              </MDBCol>
              <MDBCol lg="9" className="pl-lg-5">
                <div>
                  <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
                    {company.title}
                  </h3>
                  <div className="mt-3 font-w-400 text-medium divlink" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{__html: converter.makeHtml(company.description)}} />
                </div>
              </MDBCol>
              </MDBRow>
          ))}
      </>
    )
  }
}

export default listCompanies
